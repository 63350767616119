









import { Component, Prop, Vue } from 'vue-property-decorator';
import AuthenticationOption from '@/models/AuthenticationOption';

@Component
export default class LoginOption extends Vue {
  @Prop()
  private option!: AuthenticationOption;
}
