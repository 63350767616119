




































import { Component, Prop, Vue } from 'vue-property-decorator';
import LoginOption from '@/components/LoginOption.vue';
import AuthenticationOption from '@/models/AuthenticationOption';

@Component({
  components: {
    LoginOption
  }
})
export default class LoginPage extends Vue {
  @Prop({ default: false })
  private authError!: boolean;

  private showBasicAuth: boolean = false;

  public get oauthOptions(): AuthenticationOption[] {
    const options: string[] = process.env.VUE_APP_OATH_OPTIONS.split('|');
    const parsedOptions: AuthenticationOption[] = [];
    options.forEach((option: string) => {
      const split: string[] = option.split(';');
      parsedOptions.push({
        name: split[0],
        url: split[1],
        logo: split[2]
      });
    });
    return parsedOptions;
  }

  public get authLoginUrl(): string {
    return process.env.VUE_APP_AUTH_LOGIN_URI;
  }
}
