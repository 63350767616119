






import { Component, Vue } from 'vue-property-decorator';
import LoginPage from '@/components/LoginPage.vue';

@Component({
  components: {
    LoginPage
  }
})
export default class App extends Vue {
  private error: boolean = false;
  public mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.error = urlParams.has('error');
  }
}
